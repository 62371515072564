import { createBrowserHistory } from "history";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Login from "./Login";
import styles from "./LoginModal.module.scss";

import { login, logout } from "~/auth/authRedcuer";
import Button from "~/components/button/Button";
import Modal from "~/components/modal/Modal";
import { t } from "~/i18n";
import { AppDispatch } from "~/state/store";

type LoginModalProps = {
  onClose: () => void;
};

export default function LoginModal({ onClose }: LoginModalProps) {
  const [showLogin, setShowLogin] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const handleLogOut = () => {
    toast.remove();
    dispatch(logout(() => onClose()));
    const history = createBrowserHistory();
    history.push("/login");
  };

  const handleLogin = (email, password) => {
    dispatch(
      login({
        email,
        password,
        cb: () => {
          window.location.reload();
        }
      })
    );
  };

  return (
    <Modal
      className={styles.modal}
      onClose={onClose}
      title="You've been logged out"
      contentClass={styles.content}
    >
      {showLogin ? (
        <Login onLogin={handleLogin} isInModal />
      ) : (
        <div className={styles.sessionExpired}>
          <p>{t("login.sessionExpired")}</p>
          <div className={styles.actions}>
            <Button onClick={handleLogOut} inverted>
              Stay logged out
            </Button>

            <Button onClick={() => setShowLogin(true)}>Log in again</Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
