export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Base64String: { input: any; output: any; }
  Date: { input: any; output: any; }
  LocalDate: { input: any; output: any; }
  LocalDateTime: { input: any; output: any; }
  Long: { input: any; output: any; }
  Map_LocalDate_BooleanScalar: { input: any; output: any; }
  ObjectScalar: { input: any; output: any; }
};

export type ActOnInviteOutput = {
  __typename?: 'ActOnInviteOutput';
  coachId: Maybe<Scalars['Long']['output']>;
  failedPCIs: Maybe<Array<Maybe<ProgramCatalogItemLocaleDto>>>;
  grantedCoachPrivileges: Scalars['Boolean']['output'];
  successPCIs: Maybe<Array<Maybe<ProgramCatalogItemLocaleDto>>>;
};

export type ActOnUserDtoInput = {
  careManagerAction: Scalars['Int']['input'];
  carePriorityType: InputMaybe<CarePriorityType>;
  timeOfRanking: InputMaybe<Scalars['LocalDateTime']['input']>;
  userId: Scalars['Long']['input'];
};

export type BehaviouralSurveyInstance = {
  __typename?: 'BehaviouralSurveyInstance';
  answeredAt: Maybe<Scalars['LocalDate']['output']>;
  exercise: Maybe<Scalars['Int']['output']>;
  goalSetting: Maybe<Scalars['Int']['output']>;
  manageNegativity: Maybe<Scalars['Int']['output']>;
  menoKnowledge: Maybe<Scalars['Int']['output']>;
  practiceMindfulness: Maybe<Scalars['Int']['output']>;
  totalScore: Maybe<Scalars['Int']['output']>;
};

export type BehaviouralSurveyMetadata = {
  __typename?: 'BehaviouralSurveyMetadata';
  messageSuggestion: Array<Maybe<MessageSuggestionDto>>;
  week1: Maybe<BehaviouralSurveyInstance>;
  week6: Maybe<BehaviouralSurveyInstance>;
  week12: Maybe<BehaviouralSurveyInstance>;
};

export type CareManagerDto = {
  __typename?: 'CareManagerDto';
  careManagerExists: Scalars['Boolean']['output'];
  careManagerId: Maybe<Scalars['Long']['output']>;
  email: Maybe<Scalars['String']['output']>;
  imageHref: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  privileges: Maybe<Scalars['Long']['output']>;
  role: Maybe<Scalars['String']['output']>;
};

export type CareManagerReassignmentDtoInput = {
  locale: InputMaybe<Scalars['String']['input']>;
  programCatalogItemId: InputMaybe<Scalars['Long']['input']>;
  receivingCareManagerIds: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
};

export type CareManagerTaskDto = {
  __typename?: 'CareManagerTaskDto';
  carePriorities: Array<Maybe<CarePriorityType>>;
  estimatedUserCompletionTimeSeconds: Scalars['Long']['output'];
  onHold: Scalars['Boolean']['output'];
  onHoldUntil: Maybe<Scalars['LocalDateTime']['output']>;
  profilePictureUrl: Maybe<Scalars['String']['output']>;
  programCatalogItemName: Scalars['String']['output'];
  timeOfRanking: Scalars['LocalDateTime']['output'];
  userFullName: Scalars['String']['output'];
  userId: Scalars['Long']['output'];
};

export type CareManagerTasksDto = {
  __typename?: 'CareManagerTasksDto';
  careManagerTaskDtos: Array<Maybe<CareManagerTaskDto>>;
  estimatedChurnRiskCompletionTime: Maybe<Scalars['Long']['output']>;
  estimatedFirstWeekMessageCompletionTime: Maybe<Scalars['Long']['output']>;
  estimatedUnansweredMessageCompletionTime: Maybe<Scalars['Long']['output']>;
  estimatedWeeklyFeedbackCompletionTime: Maybe<Scalars['Long']['output']>;
};

export type CareManagerWithPcIs = {
  __typename?: 'CareManagerWithPCIs';
  careManager: Maybe<CareManagerDto>;
  coachUserStatistics: Maybe<Array<Maybe<CoachUserStatistics>>>;
  pcis: Maybe<Array<Maybe<ProgramCatalogItemLocaleDto>>>;
};

export type CarePriorities = {
  __typename?: 'CarePriorities';
  priorities: Array<Maybe<CarePriority>>;
  timeOfRanking: Maybe<Scalars['LocalDateTime']['output']>;
};

export type CarePriority = {
  __typename?: 'CarePriority';
  metadata: Maybe<Scalars['ObjectScalar']['output']>;
  type: CarePriorityType;
};

export enum CarePriorityType {
  BehaviouralSurveyFeedback = 'BEHAVIOURAL_SURVEY_FEEDBACK',
  FirstWeek = 'FIRST_WEEK',
  HighChurnRisk = 'HIGH_CHURN_RISK',
  NewMessage = 'NEW_MESSAGE',
  ProgramProgression = 'PROGRAM_PROGRESSION',
  SurveyResultCreated = 'SURVEY_RESULT_CREATED',
  SymptomLogFeedback = 'SYMPTOM_LOG_FEEDBACK',
  WeeklyFeedback = 'WEEKLY_FEEDBACK'
}

export type ChurnRiskMetadata = {
  __typename?: 'ChurnRiskMetadata';
  millisSinceLastActivity: Maybe<Scalars['Long']['output']>;
  timeOfLastActivity: Maybe<Scalars['LocalDateTime']['output']>;
};

export type CoachUserStatistics = {
  __typename?: 'CoachUserStatistics';
  activeUserCount: Maybe<Scalars['Long']['output']>;
  coachId: Maybe<Scalars['Long']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  programCatalogItemId: Maybe<Scalars['Long']['output']>;
  totalUserCount: Maybe<Scalars['Long']['output']>;
};

export type CreateInvitesDtoInput = {
  customText: InputMaybe<Scalars['String']['input']>;
  emails: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programCatalogItemLocaleDtos: InputMaybe<Array<InputMaybe<ProgramCatalogItemLocaleDtoInput>>>;
  role: InputMaybe<Scalars['String']['input']>;
  senderCoachId: InputMaybe<Scalars['Long']['input']>;
};

export type CustomCalculatorDto = {
  __typename?: 'CustomCalculatorDto';
  calculatorId: Scalars['String']['output'];
  programCatalogItemId: Scalars['Long']['output'];
};

export type CustomCalculatorDtoInput = {
  calculatorId: Scalars['String']['input'];
  programCatalogItemId: Scalars['Long']['input'];
};

export type FirstWeekMetadata = {
  __typename?: 'FirstWeekMetadata';
  numberOfMessagesReceived: Scalars['Int']['output'];
};

export type LastUnseenMessageInformation = {
  __typename?: 'LastUnseenMessageInformation';
  oldestUnreadMessageDate: Maybe<Scalars['LocalDateTime']['output']>;
  unreadMessageCount: Scalars['Int']['output'];
};

export type MessageSuggestionAdminDto = {
  __typename?: 'MessageSuggestionAdminDto';
  messageText: Maybe<Scalars['String']['output']>;
  reachOutReason: Maybe<Scalars['Int']['output']>;
  sentAt: Maybe<Scalars['LocalDateTime']['output']>;
  suggestionId: Maybe<Scalars['String']['output']>;
  userId: Scalars['Long']['output'];
};

export type MessageSuggestionDto = {
  __typename?: 'MessageSuggestionDto';
  message: Scalars['String']['output'];
  suggestionId: Scalars['String']['output'];
};

export type MessageSuggestionSentDtoInput = {
  suggestionId: Scalars['String']['input'];
  userId: Scalars['Long']['input'];
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  actOnInvite: Maybe<ActOnInviteOutput>;
  actOnUser: Maybe<Scalars['Boolean']['output']>;
  addCustomCalculatorForPCI: Maybe<Scalars['Boolean']['output']>;
  calculateRankingScore: Maybe<Scalars['Boolean']['output']>;
  createInvites: Maybe<Scalars['Boolean']['output']>;
  createReachOutReasonWithRandomData: Maybe<Scalars['Boolean']['output']>;
  deleteCareManager: Scalars['Boolean']['output'];
  deleteCustomCalculatorForPCI: Maybe<Scalars['Boolean']['output']>;
  deleteInvites: Maybe<Scalars['Boolean']['output']>;
  markMessageSuggestionAsSent: Maybe<Scalars['Boolean']['output']>;
  recalculateRankingScore: Maybe<Scalars['Boolean']['output']>;
  removeCareManager: Scalars['Boolean']['output'];
  reportMessage: Maybe<Scalars['Boolean']['output']>;
  updateCustomCalculatorForPCI: Maybe<Scalars['Boolean']['output']>;
};


/** Mutation root */
export type MutationActOnInviteArgs = {
  coachId: InputMaybe<Scalars['Long']['input']>;
  token: InputMaybe<Scalars['String']['input']>;
};


/** Mutation root */
export type MutationActOnUserArgs = {
  actOnUser: ActOnUserDtoInput;
};


/** Mutation root */
export type MutationAddCustomCalculatorForPciArgs = {
  customCalculator: CustomCalculatorDtoInput;
};


/** Mutation root */
export type MutationCalculateRankingScoreArgs = {
  userId: Scalars['Long']['input'];
};


/** Mutation root */
export type MutationCreateInvitesArgs = {
  createInvites: CreateInvitesDtoInput;
};


/** Mutation root */
export type MutationCreateReachOutReasonWithRandomDataArgs = {
  reachOutReasonType: Scalars['Int']['input'];
  userId: Scalars['Long']['input'];
};


/** Mutation root */
export type MutationDeleteCareManagerArgs = {
  removeCareManagerDto: InputMaybe<RemoveCareManagerDtoInput>;
};


/** Mutation root */
export type MutationDeleteCustomCalculatorForPciArgs = {
  programCatalogItemId: Scalars['Long']['input'];
};


/** Mutation root */
export type MutationDeleteInvitesArgs = {
  deleteInvites: Scalars['String']['input'];
};


/** Mutation root */
export type MutationMarkMessageSuggestionAsSentArgs = {
  messageSuggestionSentDto: MessageSuggestionSentDtoInput;
};


/** Mutation root */
export type MutationRecalculateRankingScoreArgs = {
  olderThanDateTime: Scalars['LocalDateTime']['input'];
};


/** Mutation root */
export type MutationRemoveCareManagerArgs = {
  removeCareManagerDto: InputMaybe<RemoveCareManagerDtoInput>;
};


/** Mutation root */
export type MutationReportMessageArgs = {
  issues: Scalars['String']['input'];
  message: Scalars['String']['input'];
  note: InputMaybe<Scalars['String']['input']>;
  suggestionId: Scalars['String']['input'];
  userId: Scalars['Long']['input'];
};


/** Mutation root */
export type MutationUpdateCustomCalculatorForPciArgs = {
  customCalculator: CustomCalculatorDtoInput;
};

export type NextUserDto = {
  __typename?: 'NextUserDto';
  carePriorityTypes: Array<Maybe<CarePriorityType>>;
  locale: Scalars['String']['output'];
  programCatalogId: Scalars['Long']['output'];
  timeOfRanking: Scalars['LocalDateTime']['output'];
  userId: Scalars['Long']['output'];
};

export type ProgramCatalogItemLocaleDto = {
  __typename?: 'ProgramCatalogItemLocaleDto';
  locale: Maybe<Scalars['String']['output']>;
  programCatalogItemId: Maybe<Scalars['Long']['output']>;
};

export type ProgramCatalogItemLocaleDtoInput = {
  locale: InputMaybe<Scalars['String']['input']>;
  programCatalogItemId: InputMaybe<Scalars['Long']['input']>;
};

export type ProgramProgressionMetadata = {
  __typename?: 'ProgramProgressionMetadata';
  day: Scalars['Int']['output'];
  messageSuggestion: Array<Maybe<MessageSuggestionDto>>;
  moduleNumber: Scalars['Int']['output'];
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  callAssistantCoachHealthcheck: Maybe<Scalars['String']['output']>;
  getAllCoachesAndInvites: Maybe<Array<Maybe<CareManagerWithPcIs>>>;
  getBehaviouralSurveyMetadata: Maybe<BehaviouralSurveyMetadata>;
  getCareManagerTasks: Maybe<CareManagerTasksDto>;
  getCarePriorities: Maybe<CarePriorities>;
  getChurnRiskMetadata: Maybe<ChurnRiskMetadata>;
  getCustomCalculators: Maybe<Array<Maybe<CustomCalculatorDto>>>;
  getEmailFromToken: Maybe<Scalars['String']['output']>;
  getFallbackMessage: Maybe<MessageSuggestionDto>;
  getFirstWeekMetadata: Maybe<FirstWeekMetadata>;
  getLastUnseenMessageInformation: Maybe<LastUnseenMessageInformation>;
  getMessageSuggestionsForUser: Maybe<Array<Maybe<MessageSuggestionAdminDto>>>;
  getNextUser: Maybe<NextUserDto>;
  getProgramProgressionMetadata: Maybe<ProgramProgressionMetadata>;
  getReachOutReasonsForUser: Maybe<Array<Maybe<ReachOutReasonAdminDto>>>;
  getSurveyResultMetadata: Maybe<SurveyResultMetadataList>;
  getTranslations: Maybe<TranslationsDto>;
  getUserActivityLastWeek: Maybe<UserActivityLastWeekDto>;
  getUserContextInfo: Maybe<UserContextInfo>;
  getUserRankings: Maybe<Array<Maybe<UserRankingAdminDto>>>;
  getUserSymptomsLoggedMetadata: Maybe<UserSymptomLogMetadata>;
};


/** Query root */
export type QueryGetAllCoachesAndInvitesArgs = {
  coachId: InputMaybe<Scalars['Long']['input']>;
};


/** Query root */
export type QueryGetBehaviouralSurveyMetadataArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetCareManagerTasksArgs = {
  coachId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetCarePrioritiesArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetChurnRiskMetadataArgs = {
  user_id: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetEmailFromTokenArgs = {
  token: InputMaybe<Scalars['String']['input']>;
};


/** Query root */
export type QueryGetFallbackMessageArgs = {
  locale: Scalars['String']['input'];
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetFirstWeekMetadataArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetLastUnseenMessageInformationArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetMessageSuggestionsForUserArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetProgramProgressionMetadataArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetReachOutReasonsForUserArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetSurveyResultMetadataArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetTranslationsArgs = {
  sourceLanguage: InputMaybe<Scalars['String']['input']>;
  targetLanguage: InputMaybe<Scalars['String']['input']>;
  translationsDto: InputMaybe<TranslationsDtoInput>;
};


/** Query root */
export type QueryGetUserActivityLastWeekArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetUserContextInfoArgs = {
  userId: Scalars['Long']['input'];
};


/** Query root */
export type QueryGetUserRankingsArgs = {
  coachId: InputMaybe<Scalars['Long']['input']>;
  userId: InputMaybe<Scalars['Long']['input']>;
};


/** Query root */
export type QueryGetUserSymptomsLoggedMetadataArgs = {
  userId: Scalars['Long']['input'];
};

export type ReachOutReasonAdminDto = {
  __typename?: 'ReachOutReasonAdminDto';
  context: Maybe<Scalars['Base64String']['output']>;
  expiresAt: Maybe<Scalars['LocalDateTime']['output']>;
  markedAsDoneAt: Maybe<Scalars['LocalDateTime']['output']>;
  reachOutReason: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Long']['output'];
  validAt: Maybe<Scalars['LocalDateTime']['output']>;
};

export type RemoveCareManagerDtoInput = {
  reassignments: InputMaybe<Array<InputMaybe<CareManagerReassignmentDtoInput>>>;
  removeCareManagerId: InputMaybe<Scalars['Long']['input']>;
};

export type SurveyResultMetadata = {
  __typename?: 'SurveyResultMetadata';
  approved: Scalars['Boolean']['output'];
  createdDate: Scalars['Date']['output'];
  score: Scalars['Int']['output'];
  surveyId: Scalars['Long']['output'];
  surveyName: Scalars['String']['output'];
  surveyResultId: Scalars['Long']['output'];
};

export type SurveyResultMetadataList = {
  __typename?: 'SurveyResultMetadataList';
  surveyResultMetadataList: Array<Maybe<SurveyResultMetadata>>;
};

export type SymptomLogDetails = {
  __typename?: 'SymptomLogDetails';
  label: Maybe<Scalars['String']['output']>;
  loggedAt: Maybe<Scalars['LocalDate']['output']>;
  severity: Maybe<Scalars['Float']['output']>;
};

export type TranslationDto = {
  __typename?: 'TranslationDto';
  id: Maybe<Scalars['Long']['output']>;
  text: Maybe<Scalars['String']['output']>;
};

export type TranslationDtoInput = {
  id: InputMaybe<Scalars['Long']['input']>;
  text: InputMaybe<Scalars['String']['input']>;
};

export type TranslationsDto = {
  __typename?: 'TranslationsDto';
  translations: Maybe<Array<Maybe<TranslationDto>>>;
};

export type TranslationsDtoInput = {
  translations: InputMaybe<Array<InputMaybe<TranslationDtoInput>>>;
};

export type UserActivityLastWeekDto = {
  __typename?: 'UserActivityLastWeekDto';
  activeDates: Scalars['Map_LocalDate_BooleanScalar']['output'];
  userId: Scalars['Long']['output'];
};

export type UserContextInfo = {
  __typename?: 'UserContextInfo';
  dayNumber: Scalars['Int']['output'];
  daysSinceEnrollment: Scalars['Int']['output'];
  latestActiveDay: Scalars['Int']['output'];
  locale: Scalars['String']['output'];
  programCatalogItemId: Scalars['Long']['output'];
  programCatalogItemName: Scalars['String']['output'];
  userActivityLastWeek: UserActivityLastWeekDto;
  userName: Scalars['String']['output'];
  weekNumber: Scalars['Int']['output'];
};

export type UserRankingAdminDto = {
  __typename?: 'UserRankingAdminDto';
  coachId: Maybe<Scalars['Long']['output']>;
  guid: Scalars['String']['output'];
  locale: Maybe<Scalars['String']['output']>;
  onHoldUntil: Maybe<Scalars['LocalDateTime']['output']>;
  programCatalogItemId: Maybe<Scalars['Long']['output']>;
  rankingScore: Maybe<Scalars['Float']['output']>;
  reachOutReasons: Maybe<Scalars['String']['output']>;
  timeOfRanking: Maybe<Scalars['LocalDateTime']['output']>;
  userId: Scalars['Long']['output'];
};

export type UserSymptomHistory = {
  __typename?: 'UserSymptomHistory';
  logDetails: Array<Maybe<SymptomLogDetails>>;
  severityScaleMax: Scalars['Float']['output'];
  severityScaleMin: Scalars['Float']['output'];
  symptomId: Scalars['Long']['output'];
  symptomTitle: Scalars['String']['output'];
};

export type UserSymptomLogMetadata = {
  __typename?: 'UserSymptomLogMetadata';
  messageSuggestion: Array<Maybe<MessageSuggestionDto>>;
  mostRecentLogDate: Maybe<Scalars['LocalDate']['output']>;
  totalSymptomLogs: Maybe<Scalars['Int']['output']>;
  userSymptomHistories: Array<Maybe<UserSymptomHistory>>;
  userSymptomStatuses: Array<Maybe<UserSymptomStatus>>;
};

export type UserSymptomStatus = {
  __typename?: 'UserSymptomStatus';
  improving: Maybe<Scalars['Boolean']['output']>;
  severe: Maybe<Scalars['Boolean']['output']>;
  severityLabel: Scalars['String']['output'];
  symptomId: Scalars['Long']['output'];
  symptomTitle: Scalars['String']['output'];
};
