import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import PRONav from "./components/proNav/PRONav";
import PROTemplate from "./components/proTemplate/PROTemplate";
import styles from "./PROPage.module.scss";

import { UserPageRouteContext } from "../userPage/UserPage.context";

import SidekickShrugging from "~/assets/sidekick-shrugging.png";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import SkeletonPROPage from "~/components/skeletons/SkeletonPROPage";
import Switch from "~/components/switch/Switch";
import ToggleSwitch from "~/components/toggleSwitch/ToggleSwitch";
import { SegmentationKey } from "~/constants/segmentationKeys";
import { useAutoTranslate } from "~/contexts/translationContext/useAutoTranslate";
import { useFeatureToggle } from "~/hooks/useApi/useFeatureToggle";
import useSurveys from "~/hooks/useApi/useSurveys";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { PROURLParams } from "~/typing/carePortalTypes";
import { Survey } from "~/typing/sidekickTypes";

const PROPage = () => {
  const {
    program_id = "",
    locale = "",
    survey_id = "",
    user_id = ""
  } = useParams<PROURLParams>();
  const { t } = useTranslation();
  const [surveyToDisplay, setSurveyToDisplay] = useState<Survey>();

  const navigate = useNavigate();

  const { autoTranslate, setAutoTranslate } = useAutoTranslate();

  const [useTextBased, setUseTextBased] = useState(false);

  const { userDetail, program } = useOutletContext<UserPageRouteContext>();
  const { trackPROViewOpened } = useAmplitudeTracking();

  const {
    surveys,
    invalidate: invalidateSurveys,
    isLoading: surveysLoading
  } = useSurveys({
    programCatalogItemId: program_id,
    locale,
    includeQuizzes: false
  });

  const { isEnabled: messageTranslationSegment } = useFeatureToggle({
    featureName: SegmentationKey.MessageTranslationSegment,
    patientId: user_id
  });

  const switchData = [
    {
      label: t("pro.text"),
      value: true
    },
    {
      label: t("pro.numerical"),
      value: false
    }
  ];

  useEffect(() => {
    if (!program) return;
    trackPROViewOpened();
  }, [program?.programCatalogItemId]);

  useEffect(() => {
    if (!surveys.length || !program) return;

    if (survey_id && surveyToDisplay?.id === survey_id) return;

    if (!survey_id) {
      const surveyToNavigateTo = program?.defaultSurveyId || surveys?.[0]?.id;
      if (surveyToNavigateTo) navigate(surveyToNavigateTo, { replace: true });
    } else {
      const survey = surveys.find((survey) => survey.id === survey_id);
      setUseTextBased(survey?.options?.displayTextView ?? true);
      setSurveyToDisplay(survey);
    }
  }, [survey_id, program, surveys]);

  const NoPROs = () => {
    if (surveysLoading) return <SkeletonPROPage />;
    return (
      <div className={styles.noSurveys}>
        <img src={SidekickShrugging} alt="shrugging sidekick" />
        <p>{t("pro.noPros", "There are no PROs for this program")}</p>
      </div>
    );
  };

  return (
    <SentryErrorBoundary
      resetState={() => invalidateSurveys()}
      transactionName="PROPage"
    >
      <div className={styles.top}>
        <PRONav
          surveys={surveys}
          latestSurveyResults={userDetail?.latestSurveyResults ?? []}
        />
        {messageTranslationSegment && (
          <div className={styles.spacedContainer}>
            <div className={styles.title}>
              {t("translation.settings.header")}
            </div>
            <div className={styles.toggleContainer}>
              <p>{t("translation.settings.autoTranslation")}</p>
              <Switch onChange={setAutoTranslate} checked={autoTranslate} />
            </div>
          </div>
        )}
        <ToggleSwitch
          switchData={switchData}
          setValue={setUseTextBased}
          currentValue={useTextBased}
          className={styles.switch}
        />
      </div>
      {surveys?.length ? (
        <PROTemplate useTextBased={useTextBased} survey={surveyToDisplay} />
      ) : (
        <NoPROs />
      )}
    </SentryErrorBoundary>
  );
};

export default PROPage;
