import { useReducer, useState, useEffect, Suspense } from "react";
import { useParams, Outlet } from "react-router-dom";

import { UserPageContext, UserPageRouteContext } from "./UserPage.context";
import { useUsersForAvatarInMessageThreads } from "./UserPage.helpers";
import styles from "./UserPage.module.scss";
import UserPageHeader from "./UserPageHeader";
import UserPageSideNav from "./UserPageSideNav";
import UserPageTopNav from "./UserPageTopNav";

import timeZoneOffsetReducer, {
  TimeZoneOffsetActions
} from "../reducers/timeZoneReducer";

import { readSmsMessages } from "~/api/requests/messageRequests";
import Layout from "~/components/layout/Layout";
import LoadingSpinner from "~/components/loadingSpinner/LoadingSpinner";
import MessageWindow from "~/components/messages/messageWindow/MessageWindow";
import { idBelongsToUser } from "~/components/messages/messageWindow/MessageWindow.helpers";
import UserNotes from "~/components/notes/UserNotesModal";
import SentryErrorBoundary from "~/components/SentryErrorBoundary";
import { TimeInMs } from "~/constants/measurements";
import { SpecialtyBehaviour } from "~/constants/specialtyBehaviours";
import { TextMessageType } from "~/constants/textMessaging";
import { getLatestUnratedSurvey } from "~/helpers/getLatestSurvey";
import { getUserMostRecentProgramInfo } from "~/helpers/user/userDetailsHelpers";
import { useCoachesForProgram } from "~/hooks/useApi/coaches/useCoachesForProgram";
import { useMessageThreads } from "~/hooks/useApi/messages/useMessageThreads";
import { useSendMessage } from "~/hooks/useApi/messages/useSendMessage";
import { useSendAdHocSMS } from "~/hooks/useApi/messages/useSendSmsMessage";
import { useSmsMessages } from "~/hooks/useApi/messages/useSmsMessages";
import { useSpecialties } from "~/hooks/useApi/specialties/useSpecialties";
import { usePrefetchCoachSuggestedMessages } from "~/hooks/useApi/useCoachSuggestedMessages";
import { useExternalUser } from "~/hooks/useApi/useExternalUser";
import useProgram from "~/hooks/useApi/useProgram";
import { useProgramSpecialties } from "~/hooks/useApi/useProgramSpecialties";
import useSurveys from "~/hooks/useApi/useSurveys";
import useUserDetail from "~/hooks/useApi/useUserDetail";
import useUrlQuery from "~/hooks/useUrlQuery";
import useUser from "~/hooks/useUser";
import SMSModal from "~/pages/program/registration/SMSModal";
import { UserURLParams } from "~/typing/carePortalTypes";
import { ExternalUserType } from "~/typing/sidekickTypes";

const UserPage = () => {
  // React hooks
  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();
  const query = useUrlQuery();

  // local state
  const [showMessages, setShowMessages] = useState(!!query.get("openMessages"));
  const [showNotes, setShowNotes] = useState(false);
  const [externalUserType, setExternalUserType] = useState<ExternalUserType>();
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [leftDate, setLeftDate] = useState("");
  const [isFinished, setFinished] = useState(false);
  const [useSMS, setUseSMS] = useState(!!query.get("useSms"));

  // State stored in redux
  const { user: authUser } = useUser();

  const [
    timeZoneState,
    timeZoneOffsetDispatch
  ] = useReducer(timeZoneOffsetReducer, { timeZoneOffset: undefined });

  // Data fetching and mutation hooks through react-query
  const { sendMessage } = useSendMessage({
    programId: program_id,
    locale,
    userId: user_id
  });
  const { program, isLoading: programLoading } = useProgram({
    programCatalogItemId: program_id,
    locale
  });
  const { specialties } = useSpecialties({});
  const { surveys } = useSurveys({ programCatalogItemId: program_id, locale });
  const { coaches } = useCoachesForProgram({
    programId: program_id,
    locale
  });

  const {
    userDetail,
    isLoading: userDetailLoading,
    invalidate: invalidateUser
  } = useUserDetail({
    programCatalogItemId: program_id,
    locale,
    userId: user_id
  });
  const {
    messageThreads,
    unreadMessages,
    updateUnreadMessages
  } = useMessageThreads({
    programCatalogItemId: program_id,
    locale,
    userId: user_id,
    messagesThreadOpen: showMessages
  });
  usePrefetchCoachSuggestedMessages({
    programId: program_id,
    locale,
    userId: user_id,
    authUserId: authUser?.userId ?? ""
  });
  const { externalUser } = useExternalUser({
    externalUserTypeId: externalUserType?.id,
    externalUserId: userDetail?.externalUser?.id
  });
  const { smsMessages } = useSmsMessages({
    programCatalogItemId: program_id,
    locale,
    externalUser,
    refetchInterval: () =>
      !showMessages
        ? TimeInMs.Second * 30
        : useSMS
        ? TimeInMs.Second * 5
        : TimeInMs.Second * 30
  });
  const { sendSmsMessage } = useSendAdHocSMS({
    programId: program_id,
    locale,
    externalUser
  });
  const { programSpecialties } = useProgramSpecialties({
    programId: program_id,
    locale
  });

  const hideEverything = () => {
    setShowNotes(false);
    setShowMessages(false);
  };

  const setTimeZoneOffset = async () => {
    timeZoneOffsetDispatch({
      type: TimeZoneOffsetActions.Set,
      payload: userDetail
    });
  };

  const usersForAvatars = useUsersForAvatarInMessageThreads({
    messageThreads,
    userDetail,
    program,
    requireProgramBeforeFetching: true
  });

  const handleShowNotes = () => {
    hideEverything();
    setShowNotes(true);
  };

  const shouldMarkSMSAsRead = () => {
    // If there are no program specialties, check if the user is the assigned coach
    if (!programSpecialties?.length) {
      return idBelongsToUser(userDetail?.assignedCoach?.userId ?? "", authUser);
    }

    // Find the primary specialty for the program
    const primarySpecialty = programSpecialties.find(
      (specialty) => specialty.behaviours === SpecialtyBehaviour.Primary
    );

    // If there are no coaches or no primary specialty, SMS should not be marked as read
    if (!coaches?.length || !primarySpecialty) {
      return false;
    }

    // Check if the user is the assigned primary specialist
    return coaches.some(
      (coach) =>
        coach.coachUserId === authUser?.id &&
        coach.specialtyId === primarySpecialty.specialtyId
    );
  };

  const handleShowMessages = (useSMS = false) => {
    setUseSMS(useSMS);
    hideEverything();
    setShowMessages(true);
    if (useSMS && shouldMarkSMSAsRead()) {
      readSmsMessages(smsMessages);
    }
  };

  const handleCloseMessages = () => {
    setShowMessages(false);
  };

  useEffect(() => {
    const setUserAndProgramData = async () => {
      if (program?.externalUserTypes && userDetail) {
        const userType = program?.externalUserTypes.find((type) => {
          return type.id === userDetail.externalUser?.externalUserTypeId;
        });
        setExternalUserType(userType);
      }
      setTimeZoneOffset();

      if (userDetail?.userPrograms) {
        const { isFinished, leftDate } = getUserMostRecentProgramInfo({
          userDetail,
          programId: program_id,
          locale
        });

        setFinished(isFinished);
        setLeftDate(leftDate);
      }
    };
    if (!userDetail || !program) return;

    setUserAndProgramData();
  }, [userDetail, program]);

  useEffect(() => {
    if (
      query.get("openMessages") &&
      query.get("useSms") &&
      smsMessages?.length > 0
    ) {
      if (showMessages && shouldMarkSMSAsRead()) {
        readSmsMessages(smsMessages);
      }
    }
  }, [smsMessages]);

  if (!program) return null;

  return (
    <Layout>
      <SentryErrorBoundary transactionName="UserPage">
        <UserPageContext.Provider
          value={{
            unreadMessages,
            updateUnreadMessages: () => undefined,
            mutateUserDetail: invalidateUser,
            userDetail,
            userDetailLoading,
            program
          }}
        >
          <UserPageTopNav
            authUser={authUser}
            externalUserType={externalUserType}
            leftDate={leftDate}
            program={program}
            setShowSMSModal={setShowSMSModal}
            timeZoneState={timeZoneState}
            userDetail={userDetail}
          />
          {showSMSModal && externalUser && (
            <SMSModal
              onClose={() => setShowSMSModal(false)}
              externalUser={externalUser}
              smsTemplate={externalUserType?.smsText}
            />
          )}
          <div className={styles.main}>
            <UserPageHeader
              onShowMessages={handleShowMessages}
              onShowNotes={handleShowNotes}
              hasUnreadSms={smsMessages.some(
                (message) =>
                  !message.seenDate &&
                  message.type === TextMessageType.UserResponse
              )}
              program={program}
            />
            <div className={styles.body}>
              <UserPageSideNav
                hasMealLogActions={userDetail?.hasMealLogActions ?? false}
                hasSurveyActions={
                  !!getLatestUnratedSurvey(
                    userDetail?.latestSurveyResults ?? [],
                    surveys
                  )
                }
                showMedicationCenterInCarePortal={
                  program.showMedicationCenterInCarePortal ?? false
                }
              />
              <div className={styles.content}>
                <Suspense fallback={<LoadingSpinner />}>
                  <Outlet
                    context={
                      {
                        program,
                        timezoneOffset: timeZoneState,
                        leftDate,
                        isFinished,
                        externalUser,
                        externalUserType,
                        setShowNotes,
                        userDetail,
                        setShowMessages,
                        isLoading: programLoading || userDetailLoading
                      } as UserPageRouteContext
                    }
                  />
                </Suspense>
              </div>
            </div>
          </div>
          {showMessages && (
            <MessageWindow
              onClose={handleCloseMessages}
              onSubmitMessage={({
                message,
                replyingToMessage,
                messageSuggestionId
              }) =>
                useSMS
                  ? sendSmsMessage({ message })
                  : sendMessage({
                      message,
                      replyingToMessage,
                      suggestionId: messageSuggestionId
                    })
              }
              patient={userDetail}
              showMessages={showMessages}
              smsSettings={{
                useSMS: useSMS,
                userHasOptedOut: externalUser?.smsOptOutType === "STOP"
              }}
              messages={useSMS ? smsMessages : messageThreads?.messages ?? []}
              authUser={authUser}
              usersForAvatars={usersForAvatars}
              unreadMessages={unreadMessages}
              updateUnreadMessages={updateUnreadMessages}
              specialtyCoaches={coaches}
              specialties={specialties}
              programThatUserIsIn={program}
            />
          )}
          {showNotes && <UserNotes onClose={() => setShowNotes(false)} />}
        </UserPageContext.Provider>
      </SentryErrorBoundary>
    </Layout>
  );
};

export default UserPage;
