import { CarePriorityType } from "~/typing/graphql/types";

export const whitelistedCarePriorityTypes = [
  CarePriorityType.BehaviouralSurveyFeedback,
  CarePriorityType.FirstWeek,
  CarePriorityType.HighChurnRisk,
  CarePriorityType.NewMessage,
  CarePriorityType.ProgramProgression,
  CarePriorityType.SymptomLogFeedback,
  CarePriorityType.WeeklyFeedback
];
