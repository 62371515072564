import { useQueryClient } from "@tanstack/react-query";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./ResultBubble.module.scss";
import ResultTooltip from "./ResultTooltip";

import CloseIcon from "~/assets/close.svg";
import Button from "~/components/button/Button";
import Notification from "~/components/notification/Notification";
import Overlay from "~/components/overlay/Overlay";
import { getBestRatingContrastTextColor } from "~/helpers/colours/contrast";
import { wordCount } from "~/helpers/string/stringHelpers";
import {
  displayErrorToast,
  displaySuccessToast
} from "~/helpers/toast/displayToast";
import { useSendMessage } from "~/hooks/useApi/messages/useSendMessage";
import { QueryKeyFactory } from "~/hooks/useApi/queryKeysFactory";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import colors from "~/styles/colors";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { UserURLParams } from "~/typing/carePortalTypes";
import { Survey, SurveyRating, SurveyResult } from "~/typing/sidekickTypes";

type ResultBubbleProps = {
  rating?: SurveyRating;
  coachRating?: SurveyRating;
  pro?: SurveyResult;
  onChangeRating?: (
    coachRatingValue: number,
    proId: string,
    initiallyHasNotification: boolean
  ) => void;
  survey?: Survey;
  hasRatings?: boolean;
  isLastPro?: boolean;
  uniqueValues?: number[];
};

const ResultBubble = ({
  rating,
  coachRating,
  pro,
  onChangeRating,
  survey,
  hasRatings,
  isLastPro,
  uniqueValues
}: ResultBubbleProps) => {
  const [showAcceptChanges, setShowAcceptChanges] = useState(false);
  const [showColorOptions, setShowColorOptions] = useState(false);
  const [showResultTooltip, setShowResultTooltip] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState(coachRating?.coachMessage || "");
  const initiallyHasNotification = Boolean(
    isLastPro && pro && !pro.coachRatingDate
  );
  const {
    trackPROResultApproved,
    trackPROResultChanged,
    trackPROResultMessageSent
  } = useAmplitudeTracking();

  const {
    program_id = "",
    locale = "",
    user_id = ""
  } = useParams<UserURLParams>();

  const {
    sendMessage,
    isSuccess: messageSuccess,
    isError: messageError
  } = useSendMessage({
    programId: program_id,
    locale,
    userId: user_id,
    trackOnSend: false
  });

  const [showNotification, setShowNotification] = useState(
    initiallyHasNotification
  );
  const { t } = useTranslation();
  const notShowingModal =
    !showAcceptChanges && !showColorOptions && !showMessageModal;

  const queryClient = useQueryClient();

  const possibleRatings = survey?.ratings;

  useEffect(() => setMessage(coachRating?.coachMessage || ""), [coachRating]);
  const useMaxSeverityAsResult = survey?.options?.useMaxSeverityAsResult;

  const modalRef = useRef(null);

  useOnClickOutside(modalRef, () => {
    if (showAcceptChanges) {
      setShowAcceptChanges(false);
    }

    if (showColorOptions) {
      setShowColorOptions(false);
    }

    if (showMessageModal) {
      setShowMessageModal(false);
    }
  });

  const handleOnClick = () => {
    if (!pro || (!hasRatings && pro.coachRatingDate)) {
      return;
    }
    if (notShowingModal) {
      setShowResultTooltip(false);
      if (showMessageModal) return;

      if (!coachRating) {
        setShowAcceptChanges(true);
      } else {
        setShowColorOptions(true);
      }
    }
  };

  const handleSubmitMessage = (e) => {
    e.preventDefault();
    sendMessage({ message });
    setMessage("");
  };

  const handleOnChangeRating = (rating) => {
    if (!onChangeRating) return;
    onChangeRating(
      rating.coachRatingValue,
      pro?.id ?? "",
      initiallyHasNotification
    );
    trackPROResultChanged({
      proResultId: parseInt(pro?.id ?? "0"),
      proName: survey?.surveyName ?? "",
      proDate: pro?.date ?? ""
    });

    // Invalidate the user programs so that the user's coach list is updated
    queryClient.invalidateQueries({
      queryKey: QueryKeyFactory.programs.users(program_id, locale)
    });
    setShowColorOptions(false);
    setShowMessageModal(true);
    setShowNotification(false);
  };

  const handleOnClickChange = () => {
    setShowAcceptChanges(false);
    setShowColorOptions(true);
  };

  const handleOnClickAccept = () => {
    if (!onChangeRating) return;
    onChangeRating(
      rating?.coachRatingValue ?? 0,
      pro?.id ?? "",
      initiallyHasNotification
    );
    trackPROResultApproved({
      proResultId: parseInt(pro?.id ?? "0"),
      proName: survey?.surveyName ?? "",
      proDate: pro?.date ?? ""
    });
    setShowAcceptChanges(false);
    setShowMessageModal(true);
    setShowNotification(false);

    // Invalidate the user programs so that the user's coach list is updated
    queryClient.invalidateQueries({
      queryKey: QueryKeyFactory.programs.users(program_id, locale)
    });
  };

  const handleOnMouseEnter = () => {
    if (pro && notShowingModal && hasRatings) setShowResultTooltip(true);
  };

  const handleOnMouseLeave = () => {
    setShowResultTooltip(false);
  };

  useEffect(() => {
    if (messageSuccess) {
      setShowMessageModal(false);
      trackPROResultMessageSent({
        proResultId: parseInt(pro?.id ?? "0"),
        proName: survey?.surveyName ?? "",
        proDate: pro?.date ?? "",
        wordCount: wordCount(message)
      });
      displaySuccessToast({ message: t("pro.messageSuccess") });
    }
  }, [messageSuccess]);

  useEffect(() => {
    if (messageError) {
      displayErrorToast({ message: t("pro.messageError") });
    }
  }, [messageError]);

  const acceptChangesModal = (
    <>
      <Overlay className={styles.overlay} />
      <div ref={modalRef} className={`${styles.acceptChanges} ${styles.modal}`}>
        <div className={styles.arrow}></div>
        <button
          onClick={handleOnClickAccept}
          type="button"
          className="btn btn-primary btn-sm"
        >
          {t("general.approve", "Approve")}
        </button>
        {(hasRatings || pro?.coachRatingDate) && (
          <button
            onClick={handleOnClickChange}
            type="button"
            className="btn btn-secondary btn-sm"
          >
            {t("general.change", "Change")}
          </button>
        )}
      </div>
    </>
  );

  const colorOptionsModal = (
    <>
      <Overlay className={styles.overlay} />
      <div ref={modalRef} className={`${styles.colorOptions} ${styles.modal}`}>
        <div className={styles.arrow}></div>
        {possibleRatings?.map((rating) => {
          return (
            <button
              type="button"
              style={{ background: rating.colour }}
              key={rating.name}
              onClick={() => handleOnChangeRating(rating)}
            ></button>
          );
        })}
      </div>
    </>
  );

  const messageModal = (
    <>
      <Overlay className={styles.overlay} />
      <div ref={modalRef} className={`${styles.messageModal} ${styles.modal}`}>
        <div className={styles.arrow}></div>

        <div className={styles.header}>
          <div className={styles.title}>
            {t("pro.messageUser", "Message user")}
          </div>
          <button
            type="button"
            className={styles.close}
            onClick={() => setShowMessageModal(false)}
          >
            <img src={CloseIcon} />
          </button>
        </div>
        <form onSubmit={handleSubmitMessage}>
          <textarea
            value={message}
            className="input"
            autoFocus
            onChange={(e) => setMessage(e.target.value)}
            rows={10}
            placeholder={t(
              "pro.messageUserPlaceholder",
              "Send a message to the user regarding this PRO"
            )}
          />
          <Button type="submit" disabled={message === ""}>
            {t("pro.sendMessage", "Send message")}
          </Button>
        </form>
      </div>
    </>
  );

  const getMaxSeverity = () => {
    const severityIds: string[] = [];
    pro?.questions?.forEach((question) => {
      question?.answers?.forEach((answer) => {
        if (answer.surveyAnswerSeverityId) {
          severityIds.push(answer.surveyAnswerSeverityId);
        }
      });
    });

    let maxSeverity;
    severityIds.forEach((severityId) => {
      const severity = survey?.answerSeverities?.find(
        (severity) => severity.id === severityId
      );
      if (!maxSeverity || (severity?.order ?? 0) > maxSeverity.order) {
        maxSeverity = severity;
      }
    });

    return maxSeverity;
  };

  const maxSeverity = useMaxSeverityAsResult ? getMaxSeverity() : null;
  const borderColor = useMaxSeverityAsResult
    ? maxSeverity?.colour
    : rating?.colour;

  const style = pro
    ? {
        borderColor: borderColor || colors.navyBlue20,
        backgroundColor: coachRating?.colour || colors.navyBlue20,
        color: getBestRatingContrastTextColor(coachRating)
      }
    : {};
  return (
    <>
      <div
        className={styles.ResultBubble}
        style={style}
        onClick={handleOnClick}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {pro && !useMaxSeverityAsResult ? pro.result : ""}
        {showAcceptChanges && acceptChangesModal}
        {showColorOptions && colorOptionsModal}
        {showMessageModal && messageModal}
        {showNotification && (
          <Notification
            size="xs"
            count={1}
            dataTestId="resultbubble-notification"
            className={styles.notification}
          />
        )}
        {showResultTooltip && (
          <ResultTooltip
            {...survey}
            rating={useMaxSeverityAsResult ? maxSeverity : rating}
            result={pro?.result}
            useMaxSeverityAsResult={useMaxSeverityAsResult}
            uniqueValues={uniqueValues}
          />
        )}
      </div>
    </>
  );
};

export default ResultBubble;
