import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import SymptomHighlight from "./SymptomHighlight";
import {
  getMissingSymptoms,
  isNewestLogWeekOld
} from "./SymptomHighlightHelpers";
import styles from "./SymptomHighlightPanel.module.scss";

import MessageSuggestion from "~/components/messageSuggestion/MessageSuggestion";
import PulseLoader from "~/components/pulseLoader/PulseLoader";
import { SymptomSeverity, SymptomTrend } from "~/constants/symptoms";
import { useGetUserSymptomsLoggedMetadata } from "~/hooks/graphql/useCareManagerTasks";
import {
  MessageSuggestionDto,
  SymptomLogDetails
} from "~/typing/graphql/types";

const cx = classNames.bind(styles);

type SymptomHighlightPanelProps = {
  userId: string;
};

const SymptomHighlightPanel = ({ userId }: SymptomHighlightPanelProps) => {
  const { user_id = "" } = useParams();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    userSymptomsLoggedMetadata,
    isLoading,
    isError
  } = useGetUserSymptomsLoggedMetadata(user_id);

  const renderSymptomHighlights = () => {
    const highlights: JSX.Element[] = [];

    const statuses = userSymptomsLoggedMetadata?.userSymptomStatuses ?? [];

    //We need to loop through the userSymptomsLoggedMetadata to get the symptoms and their logs
    //We then process the logs to get the trend of the symptom and decide how to display it
    const acuteStatusExists = statuses.some((x) => x?.improving || x?.severe);

    statuses?.forEach((symptom) => {
      let hidden = false;

      //Sort logs by date ascending order
      const sortedLogs =
        userSymptomsLoggedMetadata?.userSymptomHistories
          .find((x) => x?.symptomId === symptom?.symptomId)
          ?.logDetails?.filter(
            (log): log is SymptomLogDetails => log !== undefined
          )
          ?.sort(
            (a, b) =>
              new Date(a?.loggedAt).getTime() - new Date(b?.loggedAt).getTime()
          ) ?? [];

      //If the data is older than a week, we want to hide the symptom and gray out the data
      //The data is still visible, but not as prominent
      const weekOld = isNewestLogWeekOld(sortedLogs);

      if (acuteStatusExists) {
        hidden =
          (acuteStatusExists &&
            ((!symptom?.severe && !symptom?.improving) ||
              symptom?.severityLabel === SymptomSeverity.NotTracked)) ||
          weekOld;
      }

      let trend = SymptomTrend.Normal;
      if (symptom?.improving) {
        trend = SymptomTrend.Improvement;
      } else if (symptom?.severe) {
        trend = SymptomTrend.Severe;
      }

      if (weekOld) {
        trend = SymptomTrend.Normal;
      }

      const highlight = (
        <SymptomHighlight
          id={symptom?.symptomTitle as string}
          severity={symptom?.severityLabel as SymptomSeverity}
          hidden={hidden && !open}
          logs={sortedLogs}
          key={symptom?.symptomId}
          trend={trend}
          weekOld={weekOld}
        />
      );

      // Separate the acute and non-acute symptoms
      highlights.push(highlight);
    });

    //Sort the highlights by wether they are a week old or not, old data should be at the bottom of actual data
    //but in front of the missing symptoms
    highlights.sort((a, b) => {
      const aWeekOld = a.props.weekOld ? 2 : 1;
      const bWeekOld = b.props.weekOld ? 2 : 1;

      if (aWeekOld !== bWeekOld) {
        return aWeekOld - bWeekOld;
      }

      const aSeverity = a.props.trend === SymptomTrend.Normal ? 0 : 1;
      const bSeverity = b.props.trend === SymptomTrend.Normal ? 0 : 1;

      return bSeverity - aSeverity;
    });

    // Fill in the rest of the symptoms that don't come from the backend
    const missingSymptoms = getMissingSymptoms(statuses);

    missingSymptoms.forEach((symptom) => {
      highlights.push(
        <SymptomHighlight
          id={symptom}
          severity={SymptomSeverity.NotTracked}
          hidden={!open} // Hide the missing symptoms
          key={symptom}
          trend={SymptomTrend.Normal}
          weekOld={false}
        />
      );
    });

    return highlights;
  };

  return (
    <div className={styles.panel}>
      {isError ? (
        <div>{t("nextStep.diga.error")}</div>
      ) : isLoading ? (
        <>
          <div className={styles.loadingWrapper}>
            <PulseLoader inverted />
          </div>
        </>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.titleTimestamp}>
              <div className={styles.title}>
                {t("nextStep.diga.symptomHighlights")}
              </div>
              <div className={styles.timestamp}>
                {userSymptomsLoggedMetadata?.mostRecentLogDate}
              </div>
            </div>
            <div className={styles.button} onClick={() => setOpen(!open)}>
              {t(open ? "general.showLess" : "general.showMore")}
            </div>
          </div>

          <div
            className={cx({
              [styles.container]: true,
              [styles.contentHidden]: !open
            })}
          >
            <div
              className={cx({
                [styles.grid]: true
              })}
            >
              {renderSymptomHighlights()}
            </div>
          </div>

          <MessageSuggestion
            userId={userId}
            messages={(
              userSymptomsLoggedMetadata.messageSuggestion ?? []
            ).filter(
              (message): message is MessageSuggestionDto =>
                message !== undefined
            )}
            simulateMessageGeneration
            graphQLMessageReport
            showFallbackMessage
          />
        </>
      )}
    </div>
  );
};

export default SymptomHighlightPanel;
