import { FloatingArrow, FloatingPortal, Placement } from "@floating-ui/react";
import classNames from "classnames";
import { ReactNode, useEffect } from "react";

import styles from "./FloatingElement.module.scss";

import useCustomFloating from "~/hooks/useCustomFloating";

const cx = classNames.bind(styles);

type ControlledOpenState = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

type FloatingElementProps = {
  children: ReactNode;
  floatingContent: ReactNode;
  placement?: Placement;
  useMaxWidth?: boolean;
  showForDebugging?: boolean;
  triggerOnClick?: boolean;
  tooltipClassName?: string;
  toggleTooltip?: boolean;
  hideArrow?: boolean;
  enableShift?: boolean;
  matchWidth?: boolean;
  controlledOpenState?: ControlledOpenState;
};

const FloatingElement = ({
  children,
  floatingContent,
  placement = "top",
  useMaxWidth = true,
  showForDebugging,
  triggerOnClick,
  tooltipClassName,
  toggleTooltip,
  hideArrow,
  enableShift = true,
  matchWidth = false,
  controlledOpenState
}: FloatingElementProps) => {
  const {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    arrowRef,
    context,
    isOpen,
    setIsOpen
  } = useCustomFloating({
    placement,
    triggerOnClick,
    enableShift,
    matchWidth,
    controlledIsOpen: controlledOpenState?.isOpen,
    controlledSetIsOpen: controlledOpenState?.setIsOpen
  });

  const shouldShowTooltip = showForDebugging ? true : isOpen;

  useEffect(() => {
    console.log("fdasfas");
    if (toggleTooltip !== undefined) {
      setIsOpen(toggleTooltip);
    }
  }, [shouldShowTooltip]);

  return (
    <>
      <div
        className={styles.tooltipAnchor}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </div>

      <FloatingPortal>
        {shouldShowTooltip && (
          <div
            className={cx(styles.tooltip, {
              [styles.maxWidth]: useMaxWidth,
              [tooltipClassName ?? ""]: !!tooltipClassName
            })}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {floatingContent}
            {!hideArrow && (
              <FloatingArrow ref={arrowRef} context={context} fill="white" />
            )}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default FloatingElement;
